import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import Success from "../../../../../Images/orderDetail/Verified.png";
import Remove from "../../../../../Images/orderDetail/remove.png";
import Pending from "../../../../../Images/orderDetail/pending.png";
import DeliveryAddress from "./DeliveryAddress";
import {
  latestPaymentAtom,
  orderSummaryAtom,
} from "../../../../../store/atoms/orderSummary/orderSummary";
import { useRecoilState, useRecoilValue } from "recoil";
import { orderIdAtom } from "../../../../../store/atoms/payment/paymentAtom";
import { useNavigate } from "react-router-dom";

const ShippingDetailsSection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const orderSummary = useRecoilValue(orderSummaryAtom);
  const latestPayment = useRecoilValue(latestPaymentAtom);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/user/dashboard/orders");
  };

  return (
    <>
      {orderSummary && orderSummary.payments && (
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
          <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
            <Box>
              <img
                src={
                  latestPayment.paymentStatus === "failed"
                    ? latestPayment.paymentStatus === "pending"
                      ? Pending
                      : Remove
                    : Success
                }
                alt={"this is"}
                style={{ width: "50px", height: "50px" }}
              />
            </Box>
            <Box>
              <CustomTypography variant={"h5"} sx={{ fontWeight: "600" }}>
                Order Summary
              </CustomTypography>
            </Box>
          </Box>
          {latestPayment && latestPayment.paymentStatus && (
            <Box display={"flex"} alignItems={"center"}>
              <CustomTypography
                variant={"subtitle1"}
                sx={{
                  backgroundColor:
                    latestPayment.paymentStatus === "failed"
                      ? "rgb(255,209,208)"
                      : latestPayment.paymentStatus === "pending"
                      ? "rgb(244, 196, 48)"
                      : "rgb(175, 225, 175)",
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                }}
              >
                Payment Status:{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                    color:
                      latestPayment.paymentStatus === "failed"
                        ? "rgb(254 100 97)"
                        : "rgb(8, 143, 143)",
                    fontWeight: "600",
                  }}
                >
                  {latestPayment.paymentStatus}
                </span>
              </CustomTypography>
            </Box>
          )}

          {latestPayment.paymentStatus !== "failed" &&
            latestPayment.paymentStatus !== "pending" && (
              <Box>
                <Box
                  sx={{
                    border: "2px solid #A2A2A2",
                    minHeight: "60px",
                    borderRadius: "0.5rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <Box>
                    <CustomTypography
                      variant={"subtitle1"}
                      sx={{ fontWeight: "600" }}
                    >
                      Awesome!
                    </CustomTypography>
                  </Box>
                  <Box>
                    <CustomTypography
                      variant={"subtitle2"}
                      sx={{ color: "#777474" }}
                    >
                      You will receive order and shipping updates via email.
                    </CustomTypography>
                  </Box>
                </Box>
              </Box>
            )}
          <Box
            padding={isSmallScreen ? 0 : "0 1rem"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                Easily track all your HelloFi orders!
              </CustomTypography>
            </Box>
            <Box>
              <Button
                onClick={handleNavigate}
                variant="contained"
                sx={{
                  backgroundColor: "rgb(6, 155, 170)",
                  padding: "0.2rem 1rem",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "rgb(6, 155, 170)",
                    boxShadow: "none",
                  },
                }}
              >
                Go To My Orders
              </Button>
            </Box>
          </Box>
          <Box>
            <DeliveryAddress latestPayment={latestPayment} />
          </Box>

          {latestPayment && latestPayment.paymentStatus && (
            <Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  minHeight: "50px",
                  border: "2px solid #A2A2A2",
                  borderRadius: "0.5rem",
                  padding: "0.5rem 1rem",
                }}
              >
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{ fontWeight: 600 }}
                >
                  Payment Type
                </CustomTypography>
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{ fontWeight: 600, textTransform: "capitalize" }}
                >
                  {latestPayment.paymentType === "full"
                    ? "prepaid"
                    : latestPayment.paymentType}
                </CustomTypography>
              </Box>
            </Box>
          )}

          <Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                border: "2px solid #A2A2A2",
                borderRadius: "0.5rem",
                minHeight: "50px",
                padding: "0.5rem 1rem",
              }}
            >
              <CustomTypography variant={"subtitle1"} sx={{ fontWeight: 600 }}>
                Estimated Delivery Date
              </CustomTypography>
              <CustomTypography variant={"subtitle2"} sx={{ fontWeight: 600 }}>
                5-7 business days
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ShippingDetailsSection;
