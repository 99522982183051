import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { cartTotals } from "../../../../store/selectors/addToCart/addToCart";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";
import PriceDetails from "./priceDetails/PriceDetails";
import AddressWrapper from "./AddressWrapper";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import { useNavigate } from "react-router-dom";

const AddAddress = () => {
  const userId = localStorage.getItem("userId");
  const { cartData, loading, error, refetch } = useGetCartItems(userId);
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
    if (cartData && cartData.items.length === 0) {
      navigate("/checkout/cart");
    } else if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, [refetch, cartData]);

  if (loading) {
    return <>Loading....</>;
  }
  if (error) {
    return <>Error..</>;
  }
  return (
    <>
      <MuiBackdrop />
      <Box>
        <Box display={"flex"} flexDirection={"row"}>
          <Box flexBasis={"65%"} sx={{ width: "100%" }}>
            <AddressWrapper />
          </Box>
          <Box flexBasis={"35%"} sx={{ width: "100%" }}>
            <PriceDetails />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddAddress;
