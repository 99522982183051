import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
  Dropdown,
  Card,
} from "react-bootstrap";
import {
  GET_ALL_BUY_ORDERS,
  QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN,
  QUERY_GET_BUYING_ORDERS_USERID,
} from "../../graphql/Query";
import { QUERY_GET_ALL_USER } from "../../graphql/Query";
import { MUTATION_GET_USER } from "../../graphql/Mutations";
import { MUTATION_UPDATE_BUY_ORDERS_BY_ID } from "../../graphql/Mutations";
import { useMutation, useQuery } from "@apollo/client";

import { FaEye, FaPen } from "react-icons/fa";
import React, { useState } from "react";

const AllBuyingOrders = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const userId = localStorage.getItem("userId");
  const [orderStatus, setOrderStatus] = useState("");

  const [updateBuyOrderStatusById] = useMutation(
    MUTATION_UPDATE_BUY_ORDERS_BY_ID,
    {
      refetchQueries: [QUERY_GET_BUYING_ORDERS_USERID],
    }
  );

  const {
    data: getAllItems,
    loading,
    error,
    refetch,
  } = useQuery(QUERY_GET_ALL_BUY_ORDERS_FOR_ADMIN);

  // console.log(getAllItems);

  let count = 1;
  const { data: getBuyorders, loading: OrderLoading } =
    useQuery(GET_ALL_BUY_ORDERS);

  const { data: AllUserData, loading: Alluserloading } =
    useQuery(QUERY_GET_ALL_USER);
  // console.log(selectedOrder);
  const handleStatusUpdate = (id, data) => {
    setSelectedOrder(data);
    setOrderStatus(
      data?.order?.payments[data?.order?.payments?.length - 1].paymentStatus ===
        "pending"
        ? "pending"
        : data?.unit?.status?.length === 0
        ? "failed"
        : data?.unit?.status[data?.unit?.status.length - 1]?.status
    );
    setShow(true);
  };

  console.log(selectedOrder);
  const handleSubmit = () => {
    updateBuyOrderStatusById({
      variables: {
        orderId: selectedOrder.unit.id,
        status: orderStatus,
      },
    });
    handleClose();
  };
  const handleOrderDetail = (order) => {
    setSelectedOrder(order);
    setShow2(true);
  };
  let totalWithoutDiscount = 0;
  return (
    <>
      <h1
        style={{
          fontSize: 20,
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        Buying Orders
      </h1>
      <Table striped bordered style={{ marginTop: 10 }}>
        <thead className="table-head">
          <tr>
            <th>s.no.</th>
            <th>Order No</th>
            <th>Payment Method</th>
            <th>Payment Status</th>
            <th>Payment Type</th>
            <th>User Name </th>
            <th>contact / alternative contact </th>
            <th>Address</th>
            <th>Status</th>
            <th>Order Details</th>
            {/* <th>User Details</th> */}
          </tr>
        </thead>
        <tbody>
          {OrderLoading ? (
            <Spinner
              animation="border"
              variant="success"
              className="mx-auto d-block"
            />
          ) : (
            getAllItems &&
            getAllItems.getAllBuyOrdersForAdmin
              .slice(0)
              .reverse()
              .map((data) => (
                <tr className="table-data">
                  <td>{count++}</td>
                  <td> {data?.order?.orderNo} </td>
                  <td>
                    {" "}
                    {
                      data?.order?.payments[data?.order.payments?.length - 1]
                        .paymentMethod
                    }{" "}
                  </td>
                  <td>
                    {
                      data?.order?.payments[data?.order.payments?.length - 1]
                        .paymentStatus
                    }
                  </td>
                  <td>
                    {" "}
                    {
                      data?.order?.payments[data?.order.payments?.length - 1]
                        .paymentType
                    }
                  </td>
                  <td>
                    {data?.user?.fName} {data?.user.lName}
                  </td>
                  <td>
                    {data?.order?.address?.contact}
                    {data?.order?.address?.alternateContactNo
                      ? `/${data.order.address.alternateContactNo}`
                      : null}
                  </td>
                  <td>
                    {`Name: ${data?.order?.address?.name}`}
                    <br />
                    {`Address: ${data?.order?.address?.address}, ${data?.order?.address?.cityDistrict}, ${data?.order?.address?.state}, ${data?.order?.address?.pinCode}`}
                    <br />
                    {data?.order?.address?.landMark && (
                      <>
                        {`Landmark: ${data.order.address.landMark}`}
                        <br />
                      </>
                    )}
                    {`Email: ${data?.order?.address?.email}`}
                    <br />
                    {data?.order?.address?.gst && (
                      <>
                        {`GST: ${data.order.address.gst}`}
                        <br />
                      </>
                    )}
                  </td>
                  <td>
                    {data?.order?.payments[data?.order?.payments?.length - 1]
                      .paymentStatus === "pending"
                      ? "pending"
                      : data?.unit?.status?.length === 0
                      ? "failed"
                      : data?.unit?.status[data?.unit?.status.length - 1]
                          ?.status}
                    <span
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={() => handleStatusUpdate(data.id, data)}
                    >
                      {" "}
                      <FaPen />{" "}
                    </span>
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOrderDetail(data)}
                  >
                    {" "}
                    <FaEye />{" "}
                  </td>
                  {/*
                                    <td className='mx-auto d-block' style={{ cursor: "pointer" }} onClick={() => handleUserDetails(data.userId)} >  <FaEye /> </td> */}
                </tr>
              ))
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Product Status
            </Dropdown.Toggle>
            <div style={{ fontWeight: "bold" }}>{orderStatus}</div>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setOrderStatus("shipped")}>
                Shipped
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus("outfordelivery")}>
                Out For Delivery
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus("delivered")}>
                Delivered
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus("cancelled")}>
                Cancelled
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped bordered hover>
            <thead className="table-head">
              <th>Product Name </th>
              <th>Product Condition </th>
              <th>Product price </th>
              <th>Product quantity </th>
              <th>Product Image </th>
            </thead>
            <tbody>
              <>
                <tr>
                  <td> {selectedOrder?.product?.productName} </td>
                  <td> {selectedOrder?.product?.condition} </td>
                  <td> {selectedOrder?.product?.productPrice} </td>
                  <td> {selectedOrder?.quantity} </td>
                  <td>
                    <Image
                      fluid
                      src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${selectedOrder?.product?.image[0]}`}
                      style={{ height: "4rem", margin: "3px" }}
                    ></Image>{" "}
                  </td>
                </tr>
              </>
              <tr className="fw-bold">
                <td>Total</td>

                <td>Rs. {selectedOrder?.order?.totalAmount}</td>
              </tr>
              <tr className="fw-bold">
                <td>Coupon Discount</td>

                <td>Rs.{selectedOrder?.order?.couponDiscount}</td>
              </tr>
              <tr className="fw-bold">
                <td> Paid Amount </td>
                <td>
                  {" "}
                  Rs.
                  {selectedOrder?.order?.remainingAmount === 0
                    ? selectedOrder?.order?.totalAmount
                    : 0}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllBuyingOrders;
