// CartItems.js
import { Box, Button, Checkbox, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useRecoilValue } from "recoil";
import Cart from "./Cart";
import CartItemCloseModal from "../modals/CartItemCloseModal";
import { countOfSelectedItems } from "../../../../../store/selectors/addToCart/addToCart";
import { RemoveCircle } from "@mui/icons-material";
import useEmptyCart from "../../../hooks/addToCart/useEmptyCart";
import { cartItems } from "../../../../../store/atoms/addToCart/addToCart";

const CartItems = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const userId = localStorage.getItem("userId");
  const cartData = useRecoilValue(cartItems);
  // console.log(cartData);
  const [modalOpen, setModalOpen] = useState(false);
  const { emptyCart } = useEmptyCart();
  const handleEmptyCart = () => {
    emptyCart(userId);
  };

  const handleModelOpen = () => {
    setModalOpen(true);
  };

  const countOfItems = useRecoilValue(countOfSelectedItems);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ margin: isSmallScreen ? "0.5rem 0" : "1.5rem 0" }}
        >
          <Box>
            <CustomTypography sx={{ fontWeight: "600" }}>
              {countOfItems === cartData.count ? (
                <Checkbox
                  checked={true}
                  sx={{
                    "&.Mui-checked": {
                      color: "rgb(6, 155, 170)",
                    },
                  }}
                />
              ) : (
                <Checkbox
                  checked={true}
                  checkedIcon={<RemoveCircle />}
                  sx={{
                    "&.Mui-checked": {
                      color: "rgb(6, 155, 170)",
                    },
                  }}
                />
              )}
              {cartData.count} ITEMS SELECTED
            </CustomTypography>
          </Box>
          <Box>
            <Button onClick={handleModelOpen}>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "600", color: "#535766" }}
              >
                REMOVE
              </CustomTypography>
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={isSmallScreen ? "1rem" : "0.5rem"}
        >
          {cartData.items.map((item) => (
            <Cart key={item.id} cartData={item} cartId={cartData.id} />
          ))}
        </Box>
      </Box>
      <CartItemCloseModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        removeItemFromCart={handleEmptyCart}
        modalTitle={`Empty Cart`}
        modalDescription={"Are you sure you want to empty the cart?"}
        oneItem={false}
      />
    </>
  );
};

export default CartItems;
