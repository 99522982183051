import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Navigation from "../Components/Navigation";
import ProfileSideBar from "./profileSideBar/ProfileSideBar";
import ResponsiveContainer from "../muicomponents/shared/ResponsiveConatiner";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";

const ProfileLayout = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box>
      <Navigation />
      <ResponsiveContainer
        sx={{
          margin: "1rem auto",
          padding: isSmallScreen ? 0 : "0 1rem",
          maxWidth: "1100px",
          minHeight: "400px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          {!isSmallScreen && <ProfileSideBar />}

          <Outlet />
        </Box>
      </ResponsiveContainer>
      <Footer />
    </Box>
  );
};

export default ProfileLayout;
