import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ShippingDetailsSection from "./shippingDetails/ShippingDetailsSection";
import OrderSummary from "./orderSummary/OrderSummary";
import { useParams } from "react-router-dom";
import { ORDER_SUMMARY } from "../../../../graphql/Mutations";
import { useQuery } from "@apollo/client";
import useGetOrderSummary from "../../hooks/productDetails/useGetOrderSummary";

const OrderDetail = () => {
  const { orderId } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { orderSummary, loading, error } = useGetOrderSummary(orderId);

  if (loading) {
    return <>Loading....</>;
  }

  if (error) {
    return <>Error {error.message}</>;
  }

  return (
    <Box display={"flex"} flexDirection={isSmallScreen ? "column" : "row"}>
      <Box
        flexBasis={"55%"}
        sx={{
          borderRight: isSmallScreen ? "none" : "1px solid #A2A2A2",
          padding: "1rem ",
          minWidth: "300px",
        }}
      >
        <ShippingDetailsSection />
      </Box>
      <Box
        flexBasis={"45%"}
        sx={{
          padding: isSmallScreen ? "0 1rem 1rem 1rem" : "1rem",
          minWidth: "300px",
        }}
      >
        <OrderSummary />
      </Box>
    </Box>
  );
};

export default OrderDetail;
