import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Orders from "../components/orders/Orders";

const OrdersPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box flexBasis={isSmallScreen ? "100%" : "70%"} sx={{ minWidth: "300px" }}>
      <Orders />
    </Box>
  );
};

export default OrdersPage;
