import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  ApolloProvider,
} from "@apollo/client";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import useSnackbarNotifier from "./features/shared/hooks/useSnackbarNotifier";
const httpLink = new HttpLink({
  // uri: "http://localhost:4000/graphql",
  uri: "https://hellofi-backend-swart.vercel.app/graphql",
  // uri: "https://hellofi-backend.vercel.app/graphql",
  // uri: "https://boar-trusting-urgently.ngrok-free.app/graphql",
});

const wsLink = new WebSocketLink({
  // uri: "wss:localhost:4000/graphql",
  uri: "wss:hellofi-backend-swart.vercel.app/graphql",
  // uri: "wss:hellofi-backend.vercel.app/graphql",
  // uri: "wss:boar-trusting-urgently.ngrok-free.app/graphql",
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // const showSnackbar = useSnackbarNotifier();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // showSnackbar(message, "error");
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    // Handle network errors, like showing a notification
    // Example: showSnackbar("Network error. Please check your connection.", "error");
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, splitLink]),
  connectToDevTools: false,
});

export default client;
