import { Box, Slide, Typography, Modal } from "@mui/material";
import { ThumbUpOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import ConditionModal from "./ConditionModal";
import FlexBetween from "../../muiComponents/FlexBetween";
import CustomCard from "../../muiComponents/CustomCard";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import FlexCenter from "../../muiComponents/FlexCenter";

const ProductCondition = ({ condition }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    description: [],
  });

  const conditionNames = {
    unboxed: {
      condition: ["No Visible Scratches or marks.", "Next to Brand New."],
      accessories: "All Original Accessories as mentioned in the Box.",
      warranty: "Under Brand Warranty.",
    },
    superb: {
      condition: [
        "Barely visible signs of usage if any.",
        "No Dents/Cracks/Chips.",
      ],
      accessories: "Original Accessories may/may not be included.",
      warranty:
        "May/May not be under Brand Warranty. If not under Brand Warranty HelloFi Warranty will be covered",
    },

    good: {
      condition: [
        "Few Visible Scratches.Barely visible dents if any.",
        "No Cracks/Chips/Functional defects.",
      ],
      accessories: "Original Accessories may/may not be included.",
      warranty: ' Months Service Warranty from HelloFi.",',
    },
    fair: {
      condtion: [
        "More visible scratches.",
        "Minor Dents/Cracks may be visible.",
        "No Functional defects",
      ],
      accessories: "Original Accessories may/may not be included.",
      warranty: "No Warranty.",
    },
    "partially-fair": {
      condition: ["Scratches/Dents/Cracks/ Chips.", "Some Functional defects."],
      accessories: "Original Accessories may/may not be included.",
      warranty: "No Warranty.",
    },
  };

  const handleModalOpen = () => {
    setModalData({
      title: `Condition : ${condition}`,
      description:
        conditionNames[condition.toLowerCase()] || "Unknown Condition",
    });
    setModalOpen(true);
  };

  return (
    <>
      <CustomCard>
        <FlexBetween>
          <FlexCenter gap={"0.5rem"}>
            <ThumbUpOutlined style={{ color: "rgb(119, 119, 119)" }} />
            <CustomTypography
              variant="subtitle1"
              sx={{ fontWeight: "500", color: "rgb(119, 119, 119)" }}
            >
              Condition:
            </CustomTypography>
            <CustomTypography
              variant="subtitle1"
              sx={{ color: "#36454F", fontWeight: "700" }}
            >
              {condition}
            </CustomTypography>
          </FlexCenter>
          <FlexCenter>
            <CustomTypography
              variant="subtitle2"
              style={{
                textDecoration: "underline",
                color: "#0866ff",
                cursor: "pointer",
              }}
              onClick={handleModalOpen}
            >
              know more <ArrowForwardOutlined fontSize="small" />
            </CustomTypography>
          </FlexCenter>
        </FlexBetween>
      </CustomCard>
      <ConditionModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalData={modalData}
        isCondition={true}
      />
    </>
  );
};

export default ProductCondition;
