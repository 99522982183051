import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import ListComponent from "../../muiComponents/ListComponent";
import Brand from "./filterComponents/Brand";
import Condition from "./filterComponents/Condition";
import { availableFiltersSelector } from "../../../../store/selectors/productListing/productListing";
import {
  categoryState,
  selectedFilterState,
  sortState,
} from "../../../../store/atoms/productListing/productListing";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import Storage from "./filterComponents/Storage";
import Ram from "./filterComponents/Ram";
import Price from "./filterComponents/Price";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";

const Filter = ({ isSmall, handleModalClose }) => {
  let { category } = useParams();
  const categoryAtom = useRecoilValue(categoryState);
  const availableFilters = useRecoilValueLoadable(
    availableFiltersSelector(category)
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [checkedFilterState, setCheckedFilterState] =
    useRecoilState(selectedFilterState);
  const setSortState = useSetRecoilState(sortState);
  const [tempFilters, setTempFilters] = useState(checkedFilterState);

  useEffect(() => {
    if (!isSmall) {
      if (category != categoryAtom) {
        setCheckedFilterState((prevFilters) => ({
          ...prevFilters,
          brand: [],
          condition: [],
          price: ["0", "250000"],
          storage: [],
          ram: [],
          connectivity: [],
          warranty: [],
        }));
        setSortState({
          field: "",
          order: "",
        });
      }
    }
  }, [category]);

  const handleApplyFilters = () => {
    setCheckedFilterState(tempFilters);
    if (handleModalClose) handleModalClose();
  };

  const handleClearFilters = () => {
    const initialFilters = {
      brand: [],
      condition: [],
      price: ["0", "250000"],
      storage: [],
      ram: [],
      connectivity: [],
      warranty: [],
    };
    setTempFilters(initialFilters);
    setCheckedFilterState(initialFilters);
    if (handleModalClose) handleModalClose();
  };

  if (availableFilters.state === "loading") {
    return <>Loading</>;
  }

  if (availableFilters.state === "hasValue") {
    return (
      <>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            width: "100%",
            borderRadius: "0.5rem",
            position: isSmall ? "relative" : "sticky",
            maxHeight: isSmall ? "90vh" : "calc(100vh - 110px)",
            overflowY: isSmallScreen ? "" : "scroll",
            top: isSmall ? "0px" : "95px",
            boxShadow:
              "0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11)",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            top={10}
            sx={{
              borderBottom: "1px solid #D3D3D3",
              padding: "1rem",
            }}
          >
            <CustomTypography variant="h6" sx={{ fontWeight: "600" }}>
              Filters:
            </CustomTypography>
            {isSmall && (
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            )}
            {!isSmall && (
              <CustomTypography
                variant="subtitle2"
                sx={{
                  color: "rgb(6, 155, 170)",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleClearFilters}
              >
                Clear Filters
              </CustomTypography>
            )}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"0.1rem"}
            sx={{ overflowY: isSmallScreen ? "scroll" : "" }}
          >
            <Brand
              brands={availableFilters.contents.brand}
              checkedBrands={
                isSmallScreen ? tempFilters.brand : checkedFilterState.brand
              }
              setTempFilters={
                isSmallScreen ? setTempFilters : setCheckedFilterState
              }
            />
            <Condition
              conditions={availableFilters.contents.condition}
              checkedConditions={
                isSmallScreen
                  ? tempFilters.condition
                  : checkedFilterState.condition
              }
              setTempFilters={
                isSmallScreen ? setTempFilters : setCheckedFilterState
              }
            />
            <Price
              price={availableFilters.contents.price}
              sliderPrice={
                isSmallScreen ? tempFilters.price : checkedFilterState.price
              }
              setTempFilters={
                isSmallScreen ? setTempFilters : setCheckedFilterState
              }
            />
            <Storage
              storage={availableFilters.contents.storage}
              checkedStorage={
                isSmallScreen ? tempFilters.storage : checkedFilterState.storage
              }
              setTempFilters={
                isSmallScreen ? setTempFilters : setCheckedFilterState
              }
            />
            <Ram
              ram={availableFilters.contents.ram}
              checkedRam={
                isSmallScreen ? tempFilters.ram : checkedFilterState.ram
              }
              setTempFilters={
                isSmallScreen ? setTempFilters : setCheckedFilterState
              }
            />
            {isSmall && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                sx={{
                  position: "fixed",
                  bottom: "1rem",
                  left: 0,
                  right: 0,
                  backgroundColor: "white",
                  padding: "0 1rem",
                  zIndex: 1000,
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "3rem",
                      backgroundColor: "rgb(6, 155, 170)",
                      "&:hover": {
                        backgroundColor: "rgb(6, 155, 170)",
                      },
                    }}
                    onClick={handleClearFilters}
                  >
                    <CustomTypography
                      variant={"subtitle1"}
                      sx={{ fontWeight: "600" }}
                    >
                      Clear All
                    </CustomTypography>
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "3rem",
                      backgroundColor: "rgb(6, 155, 170)",
                      "&:hover": {
                        backgroundColor: "rgb(6, 155, 170)",
                      },
                    }}
                    onClick={handleApplyFilters}
                  >
                    <CustomTypography
                      variant={"subtitle1"}
                      sx={{ fontWeight: "600" }}
                    >
                      Apply
                    </CustomTypography>
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  }

  return null;
};

export default Filter;
