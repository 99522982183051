import React, { useEffect } from "react";
import NavbarCartMobile from "../../../../../layout/NavbarCartMobile";
import AddressList from "./AddressList";
import useGetAllAddress from "../../../hooks/addAddress/useGetAllAddress";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useGetCartItems from "../../../hooks/addToCart/useGetCartItems";

const AddressListPageMobile = () => {
  const userId = localStorage.getItem("userId");
  const { allAddress, loading, error, refetch } = useGetAllAddress(userId);
  const {
    cartData,
    loading: cartDataLoading,
    error: cartDataError,
    refetch: cartDataRefetch,
  } = useGetCartItems(userId);
  const navigate = useNavigate();
  useEffect(() => {
    refetch();
    cartDataRefetch();
    if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, [cartData]);
  if (loading || cartDataLoading) {
    return <>Loading....</>;
  }
  if (error || cartDataError) {
    return <>Error:{error.message}</>;
  }
  return (
    <Box>
      <NavbarCartMobile
        title={"Select Address"}
        isAddressMain={false}
        isAddAddressPage={true}
        navigateReturn={"/checkout/address"}
      />
      {allAddress && allAddress.length > 0 ? (
        <AddressList addresses={allAddress} />
      ) : (
        <>No Address Found</>
      )}
    </Box>
  );
};

export default AddressListPageMobile;
