import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import AddressDetailComponent from "./AddressDetailComponent";
import { useRecoilValue } from "recoil";
import { orderSummaryAtom } from "../../../../../store/atoms/orderSummary/orderSummary";

const DeliveryAddress = ({ latestPayment }) => {
  console.log(latestPayment);
  const orderSummary = useRecoilValue(orderSummaryAtom);

  return (
    <>
      {orderSummary && orderSummary.address && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            border: "2px solid #A2A2A2",
            minHeight: "150px",
            borderRadius: "0.5rem",
            padding: "0.5rem 1rem",
          }}
        >
          <Box>
            <CustomTypography variant={"subtitle1"} fontWeight={"600"}>
              Delivery Address
            </CustomTypography>
          </Box>
          <AddressDetailComponent
            name={"Name"}
            value={orderSummary.address.name}
          />
          <AddressDetailComponent
            name={"Email id"}
            value={orderSummary.address.email}
          />
          <AddressDetailComponent
            name={"Address"}
            value={`${orderSummary.address.address}, ${orderSummary.address.cityDistrict}, ${orderSummary.address.state}, ${orderSummary.address.pinCode}`}
          />
          {orderSummary.address.landMark && (
            <AddressDetailComponent
              name={"Landmark"}
              value={orderSummary.address.landMark}
            />
          )}

          <AddressDetailComponent
            name={"Mobile No"}
            value={orderSummary.address.contact}
          />
          <AddressDetailComponent
            name={"GSTIN (if any)"}
            value={orderSummary.address.gst}
          />
        </Box>
      )}
    </>
  );
};

export default DeliveryAddress;
