import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import ProfileTextField from "./ProfileTextField";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  profileAtom,
  userContactAtom,
  userEmailAtom,
  userNameAtom,
} from "../../../../store/atoms/profile/profileAtoms";
import {
  MUTATION_UPDATE_CONTACT,
  MUTATION_UPDATE_EMAIL,
  MUTATION_UPDATE_USERNAME,
} from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import {
  userNameSchema,
  userEmailSchema,
  userMobileSchema,
} from "../../../../schemas/user/profile";
import useSnackbarNotifier from "../../../shared/hooks/useSnackbarNotifier";
import { allAddressPageBackdrop } from "../../../../store/atoms/addAddress/addAddressAtoms";

const Profile = () => {
  const userId = localStorage.getItem("userId");
  const showSnackbar = useSnackbarNotifier();
  const theme = useTheme();

  const setBackdropOpen = useSetRecoilState(allAddressPageBackdrop);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [userName, setUserName] = useRecoilState(userNameAtom);
  const [userEmail, setUserEmail] = useRecoilState(userEmailAtom);
  const [userContact, setUserContact] = useRecoilState(userContactAtom);
  const [isNameDisabled, setIsNameDisabled] = useState(true);
  const [isEmailDisabled, setIsEmailDisabled] = useState(true);
  const [isEmailAdded, setIsEmailAdded] = useState(false);
  const [isMobileDisabled, setIsMobileDisabled] = useState(true);

  const [nameErrors, setNameErrors] = useState({});
  const [emailErrors, setEmailErrors] = useState({});
  const [contactErrors, setContactErrors] = useState({});

  const [updateUserName, { loading: userNameLoading, error: userNameError }] =
    useMutation(MUTATION_UPDATE_USERNAME);

  const [
    updateUserEmail,
    { loading: userEmailLoading, error: userEmailError },
  ] = useMutation(MUTATION_UPDATE_EMAIL);

  const [
    updateUserContact,
    { loading: userContactLoading, error: userContactError },
  ] = useMutation(MUTATION_UPDATE_CONTACT);

  const handleChangeName = (e) => {
    setUserName({ ...userName, [e.target.name]: e.target.value });
    setNameErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
  };

  const handleChangeEmail = (e) => {
    setUserEmail({ ...userEmail, [e.target.name]: e.target.value });
    setEmailErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
  };

  const handleChangeContact = (e) => {
    setUserContact({ ...userContact, [e.target.name]: e.target.value });
    setContactErrors((prevErrors) => ({ ...prevErrors, [e.target.Name]: "" }));
  };

  const validateNameFields = useCallback(async () => {
    try {
      await userNameSchema.validate(userName, { abortEarly: false });
      setNameErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setNameErrors(newErrors);
      return false;
    }
  }, [userName]);

  const validateEmailField = useCallback(async () => {
    try {
      await userEmailSchema.validate(userEmail, { abortEarly: false });
      setEmailErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setEmailErrors(newErrors);
      return false;
    }
  });

  const validateContactField = useCallback(async () => {
    try {
      await userMobileSchema.validate(userContact, { abortEarly: false });
      setContactErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setContactErrors(newErrors);
      return false;
    }
  });

  const handleCancelName = () => {
    setIsNameDisabled(!isNameDisabled);
    setUserName({
      ...userName,
      fName: userName.originalfName,
      lName: userName.originallName,
    });
    setNameErrors({});
  };

  const handleCancelEmail = () => {
    setIsEmailDisabled(!isEmailDisabled);
    setUserEmail({
      ...userEmail,
      email: userEmail.originalEmail,
    });
    setEmailErrors({});
  };

  const handleCancelContact = () => {
    setIsMobileDisabled(!isMobileDisabled);
    setUserContact({
      ...userContact,
      contact: userContact.originalContact,
    });
    setContactErrors({});
  };

  const handleNameSave = async () => {
    try {
      setBackdropOpen(true);
      const isValid = await validateNameFields();
      if (isValid) {
        const response = await updateUserName({
          variables: {
            id: userId,
            userName: {
              fName: userName.fName,
              lName: userName.lName,
            },
          },
        });
        showSnackbar(response.data.updateUserName.message, "success");
        setIsNameDisabled(!isNameDisabled);
        setUserName({
          ...userName,
          originalfName: response.data.updateUserName.user.fName,
          originallName: response.data.updateUserName.user.lName,
        });
        setBackdropOpen(false);
      }
    } catch (error) {
      if (error.graphQLErrors) {
        showSnackbar(error.graphQLErrors[0].message, "error");
        setBackdropOpen(false);
      }
      setBackdropOpen(false);
    }
  };

  const handleEmailSave = async () => {
    try {
      const isValid = await validateEmailField();
      console.log(isValid);
      if (isValid) {
        setBackdropOpen(true);
        const response = await updateUserEmail({
          variables: {
            id: userId,
            userEmail: userEmail.email,
          },
        });
        showSnackbar(response.data.updateUserEmail.message, "success");
        if (isEmailAdded) {
          setIsEmailDisabled(!isEmailDisabled);
        }
        setIsEmailAdded(true);

        setUserEmail({
          ...userEmail,
          originalEmail: response.data.updateUserEmail.user.email,
        });
        setBackdropOpen(false);
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";
      if (
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors
      ) {
        errorMessage = error.networkError.result.errors[0].message;
        console.log(error.networkError.result);
      } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      showSnackbar(errorMessage, "error");
      setBackdropOpen(false);
    }
  };
  const handleContactSave = async () => {
    try {
      const isValid = await validateContactField();
      if (isValid) {
        setBackdropOpen(true);
        const response = await updateUserContact({
          variables: {
            id: userId,
            userContact: userContact.contact,
          },
        });
        showSnackbar(response.data.updateUserContact.message, "success");
        setIsEmailAdded(true);

        setUserContact({
          ...userContact,
          originalContact: response.data.updateUserContact.user.contact,
        });
        setBackdropOpen(false);
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";
      if (
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors
      ) {
        errorMessage = error.networkError.result.errors[0].message;
        console.log(error.networkError.result);
      } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      showSnackbar(errorMessage, "error");
      setBackdropOpen(false);
    }
  };

  useEffect(() => {
    if (userEmail?.email) {
      setIsEmailAdded(true);
    } else {
      setIsEmailAdded(false);
    }
  }, [profile]);
  useEffect(() => {}, [profile]);

  if (!profile) {
    return <>Loading...</>;
  }

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 115px)",
        width: "100%",
        backgroundColor: isSmallScreen ? "white" : "#D1E5E7",
        borderRadius: "1rem",
        padding: isSmallScreen ? "0 1rem" : "1.5rem",
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
        <Box>
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Personal Information
          </CustomTypography>
        </Box>

        <Box display={"flex"} gap={"1rem"} alignItems={"center"}>
          <CustomTypography
            variant={"subtitle1"}
            sx={{ fontWeight: "600", fontSize: "0.875rem" }}
          >
            Name
          </CustomTypography>
          <CustomTypography
            variant={"subtitle2"}
            onClick={handleCancelName}
            sx={{
              fontSize: "0.80rem",
              color: "#0077c0",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {!isNameDisabled ? "Cancel" : "Edit"}
          </CustomTypography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems={"center"}
          width={isSmallScreen ? "100%" : "80%"}
          gap={"1rem"}
        >
          <ProfileTextField
            label={"First Name"}
            name={"fName"}
            error={!!nameErrors.fName}
            helperText={nameErrors.fName}
            disabled={isNameDisabled}
            value={userName.fName}
            onChange={handleChangeName}
          />

          <ProfileTextField
            label={"Last Name"}
            disabled={isNameDisabled}
            value={userName.lName}
            error={!!nameErrors.lName}
            helperText={nameErrors.lName}
            name={"lName"}
            onChange={handleChangeName}
          />
          {!isNameDisabled && (
            <Button
              variant="contained"
              onClick={handleNameSave}
              sx={{
                height: "50px",
                textTransform: "capitalize",
                width: "100px",
                borderRadius: "0.2rem",
                padding: "0 3rem",
                backgroundColor: "rgb(6, 155, 170)",
                "&:hover": {
                  backgroundColor: "rgb(6, 155, 170)",
                },
              }}
            >
              Save
            </Button>
          )}
        </Box>

        <Box display={"flex"} gap={"1rem"} alignItems={"center"}>
          <CustomTypography
            variant={"subtitle1"}
            sx={{ fontWeight: "600", fontSize: "0.875rem" }}
          >
            Email Address
          </CustomTypography>
          {profile.email && (
            <CustomTypography
              variant={"subtitle2"}
              onClick={handleCancelEmail}
              sx={{
                fontSize: "0.80rem",
                color: "#0077c0",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {isEmailAdded ? (!isEmailDisabled ? "Cancel" : "Edit") : ""}
            </CustomTypography>
          )}
        </Box>
        <Box
          display={"flex"}
          flexDirection={isSmallScreen ? "column" : "row"}
          gap={"1rem"}
          alignItems={"center"}
        >
          <ProfileTextField
            label={"Email"}
            disabled={isEmailAdded ? isEmailDisabled : !isEmailDisabled}
            value={userEmail.email}
            error={!!emailErrors.email}
            helperText={emailErrors.email}
            placeHolder={"Please Add an Email"}
            name={"email"}
            onChange={handleChangeEmail}
          />
          {!isEmailAdded ? (
            <Button
              onClick={handleEmailSave}
              variant="contained"
              sx={{
                height: "50px",
                textTransform: "capitalize",
                width: "100px",
                borderRadius: "0.2rem",
                padding: "0 3rem",
                backgroundColor: "rgb(6, 155, 170)",
                "&:hover": {
                  backgroundColor: "rgb(6, 155, 170)",
                },
              }}
            >
              {isEmailAdded ? "Save" : "Add"}
            </Button>
          ) : (
            !isEmailDisabled && (
              <Button
                variant="contained"
                onClick={handleEmailSave}
                sx={{
                  height: "50px",
                  textTransform: "capitalize",
                  width: "100px",
                  borderRadius: "0.2rem",
                  padding: "0 3rem",
                  backgroundColor: "rgb(6, 155, 170)",
                  "&:hover": {
                    backgroundColor: "rgb(6, 155, 170)",
                  },
                }}
              >
                {isEmailAdded ? "Save" : "Add"}
              </Button>
            )
          )}
        </Box>
        <Box display={"flex"} gap={"1rem"} alignItems={"center"}>
          <CustomTypography
            variant={"subtitle1"}
            sx={{ fontWeight: "600", fontSize: "0.875rem" }}
          >
            Mobile Number
          </CustomTypography>
          <CustomTypography
            variant={"subtitle2"}
            onClick={handleCancelContact}
            sx={{
              fontSize: "0.80rem",
              color: "#0077c0",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {!isMobileDisabled ? "Cancel" : "Edit"}
          </CustomTypography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={isSmallScreen ? "column" : "row"}
          gap={"1rem"}
          alignItems={"center"}
        >
          <ProfileTextField
            label={"Mobile"}
            disabled={isMobileDisabled}
            value={userContact.contact}
            error={!!contactErrors.contact}
            helperText={contactErrors.contact}
            name={"contact"}
            onChange={handleChangeContact}
          />
          {!isMobileDisabled && (
            <Button
              variant="contained"
              onClick={handleContactSave}
              sx={{
                height: "50px",
                textTransform: "capitalize",
                width: "100px",
                borderRadius: "0.2rem",
                padding: "0 3rem",
                backgroundColor: "rgb(6, 155, 170)",
                "&:hover": {
                  backgroundColor: "rgb(6, 155, 170)",
                },
              }}
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
